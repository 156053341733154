import React, { useState, useEffect } from "react";
import "../App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import EnterMobNumber from "../components/Login/EnterMobNumber";
import Footer from "../components/Footer";
import OTPverify from "../components/Login/OTPverify";
import cookie from "cookie";
import { useAuth } from "../context/AuthContext";

// Images Import
import MainLogo from "../assets/mishrajimain.png";
import AuthVector from "../assets/authvector.png";
import { useNavigate } from "react-router-dom";

const Login = () => {
  useEffect(() => {
    AOS.init({});
    AOS.refresh();
    return () => {
      AOS.refreshHard();
    };
  }, []);

  const navigate = useNavigate();
  
  const { isAuthenticated } = useAuth();

  useEffect(()=>{
    // const cookie = cookie.parse(document.cookie);
    // const token = cookie.authToken;

    if(isAuthenticated){
      navigate("/chat");
    }

  },[isAuthenticated, navigate])

  const [showModal] = useState(true);
  const [showOtpVerify, setShowOtpVerify] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const [verifyId, setVerifyId] = useState("");

  const handlePhoneNumberSubmit = (number, verifyId) => {
    setMobileNumber(number);
    setVerifyId(verifyId);
    setShowOtpVerify(true);
  };

  return (
    <>
      <div className="imageblur">
        {showModal && (
          <div
            data-aos="zoom-in"
            className="fixed inset-0 bg-black bg-opacity-20 flex justify-center items-center"
          >
            <div className="relative backdrop-blur-3xl  w-[80vw] md:w-[50vw] shadow-xl lg:w-[33vw] bg-[#fff] px-[2vh]  py-[6vh] rounded-lg overflow-x-auto">
              <div>
                <img
                  src={MainLogo}
                  className="w-[13vh] md:w-[20vh] mx-auto"
                  alt="Mishraji Logo"
                />

                <h1 className="text-center mt-[2vh] text-[1.8vh] sm:text-[2.5vh]">
                  Trusted by{" "}
                  <span className="font-bold text-[#5D5D5D] text-[1.8vh] sm:text-[2.6vh]">
                    1+ Lakh Kirana Owners
                  </span>
                </h1>
              </div>

              {showOtpVerify ? (
                <OTPverify mobileNumber={mobileNumber} verifyId={verifyId} />
              ) : (
                <EnterMobNumber onSubmit={handlePhoneNumberSubmit} />
              )}

              <div className="mx-auto w-[90%] md:w-[70%]">
                <h1 className="text-[1.5vh] md:text-[1.8vh] text-[#4f4f4f] font-medium text-center">
                  By creating an account, you agree to our
                </h1>
                <p className="text-[1.6vh] md:text-[1.9vh] text-[#3d3d3d] text-center">
                  <a
                    href="https://www.kiranafriends.com/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="underline font-semibold">
                      Terms & Conditions
                    </span>
                  </a>
                  <span className="font-semibold mx-[1vh]">and</span>
                  <a
                    href="https://www.kiranafriends.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="underline font-semibold">
                      Privacy Policy
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Login;
