import { v4 as uuidv4 } from 'uuid';

// AuthService.js
const BASE_URL = "https://api.kaleyra.io/v1";
const KALEYRA_API_KEY = "A3e0b3fdde2edbb76ea7cd070c9ae1898";
const KALEYRA_SID = "HXIN1707885664IN";
const SECRET_KEY = "yfgawbvghywfvq721tr612vdy732gd8gvf861v189gyifb271gb";

const generateUserId = (mobileNumber) => {
  return `user_${mobileNumber}`; // Simple user ID generation
};

const generateToken = () => {
  return uuidv4(); // Generate a unique token using UUID
};

const sendOTP = async (toNumber) => {
    
  const url = `${BASE_URL}/${KALEYRA_SID}/verify`;
  console.log("Request URL:", url);

  const body = {
    to: {
      mobile: toNumber,
    },
    flow_id:"d5be2703-016d-463e-a258-e3985b701187"
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": KALEYRA_API_KEY,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending OTP:", error);
    throw error;
  }
};

// AuthService.js
const validateOTP = async (verifyId, otp, mobileNumber) => {
  const url = `${BASE_URL}/${KALEYRA_SID}/verify/validate`;

  const body = {
    verify_id: verifyId,
    otp: otp,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": KALEYRA_API_KEY,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();

    console.log("Response from Auth Service:", data);
    
    if (data.data && data.data.message === "OTP verified successfully.") {
      const userId = generateUserId(mobileNumber); // Generate user ID
      const token = generateToken(); // Generate UUID token
      return { success: true, token }; // Return the token
    }

    return { success: false, data }; // Return error response // Return error response
  } catch (error) {
    console.error("Error validating OTP:", error);
    throw error;
  }
};

export { sendOTP, validateOTP };