import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="">
      <div className="flex flex-col justify-center items-center h-[90vh]">
        
        <h1 className="text-[3vh] font-bold text-[#4f4f4f] md:text-[4vh]">
          404 - Not Found
        </h1>

        <p className="my-[1vh] w-[80%] mx-auto text-[2vh] md:text-[2.5vh] text-center">
          The page you are looking for does not exist.
        </p>

        <Link to="/">
          <button className="bg-[#fd6500] rounded-lg text-[2vh] md:text-[2.3vh] font-semibold text-white w-fit px-[2vh] py-[1.5vh] my-[2vh]">
            Go to Home
          </button>
        </Link>

      </div>
      <div className="absolute w-full bottom-0">
        <Footer />
      </div>
    </div>
  );
};

export default NotFound;
