import React from "react";
import Footer from "../components/Footer";
import { TypeAnimation } from "react-type-animation";
import KF_Logo from "../assets/KF_logo.png";

const MainDashboard = () => {
  return (
    <div>
      <div className="sm:w-[80vw] lg:w-[60vw] w-[88vw] mx-auto sm:pt-14 pt-8 ">
        <h1 className="text-center text-[2vh] w-fit mx-auto px-4 py-1 rounded-2xl bg-gradient-to-r from-blue-50 to-orange-100 sm:text-[1.8vh] lg:text-[2.5vh] font-medium font-inter text-[#464646] border-1 border-[#ffa568]">
          🚀 Boost your store's sales growth by using
        </h1>
      
          <img
            src="/misrajiTab.png"
            className="sm:w-[20vw] w-[50vw] mx-auto mt-6 "
            alt="Misraji"
          />
     
        <div className="flex justify-center items-center px-5 sm:px-0">
          <TypeAnimation
            className="sm:text-[2.2vh] lg:text-[3vh] text-[2.5vh] text-[#626263] mt-3 font-inter text-center"
            sequence={[
              "Your Kirana Friend who will help you grow your sales.",
              1000,
              "Your Kirana Friend who will help you increase your profits.",
              1000,
              "Your Kirana Friend who will help you modernise your store.",
              1000,
              "Your Kirana Friend who will help you convert your kirana to modern supermarket.",
              1000,
              "Your Kirana Friend who will help you open a new store.",
              1000,
            ]}
            wrapper="span"
            speed={50}
            repeat={Infinity}
          />
        </div>
        <div className="flex flex-row items-center justify-center my-3 space-x-3 ">
          <p className="text-[#4e4e4ee1] sm:text-[2vh] lg:text-[2.6vh] text-[2.2vh]">powered by</p>
          <a href="https://kiranafriends.com/" target="_blank" rel="noreferrer">
          <img
            src={KF_Logo}
            className="sm:w-[10vw] lg:w-[6vw] w-[20vw]"
            alt="kirana-friends-logo"
          />
          </a>
        </div>
        <div className="flex justify-center items-center">
          <a
            href="/login"
            className="w-fit  bg-[#fd6500] text-white font-medium font-inter px-7 py-2 mt-4 mx-auto text-[2vh] lg:text-[3vh] sm:text-[2.1vh] rounded-md shadow-lg"
          >
            ASK NOW - it's FREE
          </a>
        </div>

        <div className="flex sm:flex-row mt-5 shadow-md border-1 rounded-2xl w-[90vw] lg:w-[45vw] sm:w-[80vw]  mx-auto">
          <p className="font-inter text-[#676767] text-justify sm:p-4 text-[1.2vh] sm:text-[1.7vh] lg:text-[2.3vh] p-2">
            Late SC Misra, fondly known as Misraji, was a pioneer in supermarket
            retailing in India. Thousands of Kirana and supermarket owners
            benefited from his expertise. 'Ask Misraji' honors his legacy to
            assist Kirana owners nationwide to supermarket success.
          </p>
          <img
            src="/realMisraji.png"
            alt="real-misraji"
            className="lg:w-[10vw] sm:w-[28vw] w-[35vw] rounded-e-2xl "
          />
        </div>
      </div>

      <div className="border-t-1 py-3 mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default MainDashboard;
