// AskMishrajiService.js
import toast from "react-hot-toast";

const sendMessageToMishraji = async (
  session_uuid,
  message,
  mobileNumber,
  web
) => {
  const formData = new FormData();
  
  formData.append("session_uuid", session_uuid);
  formData.append("message", message);
  formData.append("MobileNumber", mobileNumber);
  formData.append("web", web);

  // append MobileNumber

  try {
    const response = await fetch("https://api.misraji.kiranafriends.com/send_message", {
      method: "POST",
      body: formData,
    });
    console.log("**");
    console.log("Message from API", message);
    console.log("Session_uuid from API", session_uuid);
    console.log("MobileNumber from API", mobileNumber);
    console.log("Web from API", web);

    if (!response.ok) {
      const errorDetails = await response.text();
      console.error(`Server error: ${errorDetails}`);
      throw new Error("Server error. Please try again later.");
    }

    return response.json(); // Assuming server responds with JSON data
  } catch (error) {
    console.error("Error:", error.message);
    toast.error("Server error. It looks like the server is down. Please try again later.");
    throw new Error(
      "Network error. Please check your internet connection and try again."
    );
  }
};

const sendFeedbackToMishraji = async (session_uuid, messageId, feedback) => {
  
  const formData = new FormData();
  formData.append("session_uuid", session_uuid);
  formData.append("message_id", messageId); // Add messageId to associate feedback with a specific message
  formData.append("feedback", feedback);

  try {
    const response = await fetch("https://api.misraji.kiranafriends.com/send_feedback", {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errorDetails = await response.text();
      console.error(`Server error: ${errorDetails}`);
      throw new Error("Server error. Please try again later.");
    }

    return response.json(); // Assuming server responds with JSON data
  } catch (error) {
    console.error("Error:", error.message);
    throw new Error(
      "Network error. Please check your internet connection and try again."
    );
  }
};

export { sendMessageToMishraji, sendFeedbackToMishraji };
