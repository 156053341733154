import React from "react";
import PlayStoreImg from "../assets/google_playstore.png";

const Footer = () => {
  return (
    
      <div className="w-[95%] mx-auto flex gap-y-[0.5vh] flex-col-reverse lg:flex-row justify-between items-center sm:text-[2.4vh] text-[2.2vh] text-[#303030]">
        <a
          href="https://www.kiranafriends.com/"
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-center text-[1.6vh] lg:text-[2.1vh]" >
            &copy;TRRAIN Circle Technologies Private Limited | All Rights Reserved
          </p>
        </a>

        <p className="text-[1.6vh] lg:text-[2.1vh]">Made with ❤️ in India</p>
        <a href="https://kiranafriends.com/" className="text-[1.6vh] lg:text-[2.1vh]" target="_blank" rel="noreferrer">
          About Kirana Friends
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.  kiranafriend.android&hl=en_IN&gl=US"
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-[7vh]" src={PlayStoreImg} alt="google-play-store" />
        </a>
      </div>
   
  );
};

export default Footer;
