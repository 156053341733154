import React, { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { validateOTP, sendOTP } from "../../service/AuthService";
import { useAuth } from "../../context/AuthContext";

const OTPverify = ({ mobileNumber, verifyId: initialVerifyId }) => {
  const navigate = useNavigate();
  const { login } = useAuth();
  console.log("Mobile Number:", mobileNumber); // Debugging line

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [verifyId, setVerifyId] = useState(initialVerifyId);

  const [resendTimer, setResendTimer] = useState(30);
  const [isResending, setIsResending] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isResending && resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResending(false);
      // toast.success("OTP sent successfully55!!");
      setResendTimer(30);
    }
    return () => clearInterval(interval);
  }, [isResending, resendTimer]);

  const handleChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const resendOTP = async () => {
    setIsLoading(true);
    try {
      const response = await sendOTP(mobileNumber);
      console.log(response);

      if (response.data) {
        const newVerifyId = response.data.verify_id;
        setVerifyId(newVerifyId);
        setIsLoading(false);
        toast.success("OTP sent successfully!!");
      } else {
        setIsLoading(false);
        toast.error("Failed to send OTP");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      setIsLoading(false);
      toast.error("Error resending OTP");
    }
  };

  const handleResend = () => {
    setResendTimer(30);
    setIsResending(true);
    // toast.success("Otp sent successfully!!");
    resendOTP();  
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const enteredOtp = otp.join("");
    try {
      const response = await validateOTP(verifyId, enteredOtp, mobileNumber);
      console.log("Response from validateOTP:", response); // Debugging line

      if (response.success) {
        const { token } = response; // Get the generated token
        login(token, mobileNumber); // Store the token using login function
        navigate("/chat", {state:{mobileNumber}}); // Redirect to chat
        toast.success("OTP verified successfully!!");
      } else {
        toast.error("Invalid OTP entered!!");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP!!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="sm:w-[80%]  mx-auto flex flex-col justify-center items-center">
      <h1 className="text-[1.4vh] md:text-[2.1vh] my-[1vh] text-center font-semibold w-full ">
        Enter the 6 digit code sent to +{mobileNumber}
      </h1>

      <form onSubmit={handleSubmit}>
        <div className="flex justify-center items-center space-x-2 my-[1vh]">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="tel"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(ref) => (inputRefs.current[index] = ref)}
              className="sm:w-12 sm:h-12 w-8 h-8 sm:text-[3vh] font-semibold text-[#4f4f4f] border-2 border-[#969696] rounded-md text-center focus:outline-none"
            />
          ))}
        </div>
        {/* {submittedOtp && ( // Display only if submittedOtp is not empty
          <div className="text-red-500 text-sm mt-2">
            Incorrect OTP entered:
          </div>
        )} */}
        <div>
          {isResending && (
            <h1 className="text-center text-[2.3vh] font-semibold my-[2vh]">
              Resend in{" "}
              <span className="text-[2.4vh] font-bold cursor-pointer text-[#fd6500]">
                {resendTimer} sec
              </span>
            </h1>
          )}

          {!isResending && (
            <h1 className="text-center sm:text-[2.3vh] font-semibold my-[2vh]">
              Didn't receive OTP?{" "}
              <span
                onClick={handleResend}
                className="sm:text-[2.4vh] font-bold cursor-pointer text-[#fd6500]"
              >
                Resend
              </span>
            </h1>
          )}
        </div>

        <button
          type="submit"
          className="text-[2vh] sm:text-[2.5vh] my-[2vh] rounded-md font-bold bg-[#fd6500] active:bg-[#ee6001] hover:scale-105 transition-all text-white w-[95%] mx-auto py-[1vh] md:py-[1.3vh]"
        >
          {isLoading ? (
            <div className="flex flex-row justify-center items-center">
              <div className="spinner"></div>
            </div>
          ) : (
            <>Submit</>
          )}
        </button>
      </form>
    </div>
  );
};

export default OTPverify;
