import React, { useState, useEffect, useRef } from "react";
import MishrajiInput from "../elements/MishrajiInput";
import IntroDashboard from "../components/Chat/IntroDashboard";
import { sendMessageToMishraji } from "../service/AskMishrajiService";
import mishrajiAvatar from "../assets/mishraji.png";
import userimg from "../assets/user.png";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { sendFeedbackToMishraji } from "../service/AskMishrajiService";
import cookie from "cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useLocation } from "react-router-dom";

// Function to format text with bold tags and list items
const formatOpenAIResponse = (answer) => {
  function makeBold(text) {
    return text.split(/\*\*(.*?)\*\*/).map((part, index) =>
      index % 2 === 1 ? (
        <span style={{ fontWeight: "bold" }} key={index}>
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  let lines = answer.split("\n").filter((line) => line.trim() !== "");
  let formattedAnswer = [];

  const listItemRegex = /^\d+\.\s+/;

  lines.forEach((line, index) => {
    if (listItemRegex.test(line)) {
      let itemText = line.replace(listItemRegex, "");
      formattedAnswer.push(
        <div key={index} className="ml-2 flex items-start">
          <span className="text-black">{line.match(listItemRegex)[0]}</span>
          <div className="ml-1">{makeBold(itemText)}</div>
        </div>
      );
    } else {
      formattedAnswer.push(<div key={index}>{makeBold(line)}</div>);
    }
  });

  return formattedAnswer;
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [typedText, setTypedText] = useState(""); // Hold the text as it's being "typed"
  const [responseText, setResponseText] = useState("");

  const [errorModal, setErrorModal] = useState(true);

  const session_uuid = "hg7321hgy871gfuhibi";
  const language = "en";
  const kfid = "en";
  const web = "web";

  const { mobileNumber } = useAuth();
  // console.log("Mobile Number from Context", mobileNumber);

  const MobileNumber = mobileNumber;
  console.log("Send Mobile Number to API", MobileNumber);

  const messagesEndRef = useRef(null);

  const sendMessage = async (message) => {
    const newUserMessage = {
      id: Date.now(), // Unique ID for each message
      text: message,
      sender: "user",
      direction: "outgoing",
    };
    setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setInputText("");

    try {
      setLoading(true);

      const response = await sendMessageToMishraji(
        session_uuid,
        message,
        MobileNumber,
        web
      );

      console.log("...");
      console.log("Session UID:", session_uuid);
      // console.log("newUserMessage:",newUserMessage.text)
      console.log("Message:", message);
      console.log("Language:", language);
      console.log("KFID:", kfid);
      console.log("Mobile Number:", MobileNumber);
      console.log("Web:", web);
      console.log("...");

      const responseText = response.response || "No response from the backend";

      const formattedResponse = formatOpenAIResponse(responseText);

      // Set the full response text and initialize typing state
      setResponseText(responseText);
      setTypedText(""); // Clear the previous typed text
      setTyping(true);

      // Typing effect logic
      const words = responseText.split(" ");
      let i = 0;

      // Clear any previous intervals
      if (window.typingInterval) {
        clearInterval(window.typingInterval);
      }

      const newMishrajiMessage = {
        id: Date.now() + 1, // Unique ID for each message
        text: "",
        sender: "mishraji",
        direction: "incoming",
        typing: true,
        showFeedback: false,
        showResponse: false,
      };

      setMessages((prevMessages) => [...prevMessages, newMishrajiMessage]);

      window.typingInterval = setInterval(() => {
        setTypedText((prev) => `${prev} ${words[i]}`.trim()); // Append words one by one
        i++;
        if (i >= words.length) {
          clearInterval(window.typingInterval);
          setTyping(false);
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === newMishrajiMessage.id
                ? {
                    ...msg,
                    text: formattedResponse,
                    typing: false,
                    showFeedback: true,
                  }
                : msg
            )
          );
        }
      }, 50); // Adjust typing speed as needed
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuestionClick = (question) => {
    sendMessage(question);
  };

  const sendFeedback = async (messageId, feedback) => {
    try {
      const response = await sendFeedbackToMishraji(
        session_uuid,
        messageId,
        feedback
      );
      console.log("sendFeedback Response --> ", response);
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === messageId
            ? {
                ...message,
                feedback,
                loading: false,
                showFeedback: true,
                showResponse: true,
              }
            : message
        )
      );

      setTimeout(() => {
        setMessages((prevMessages) =>
          prevMessages.map((message) =>
            message.id === messageId
              ? { ...message, showResponse: false }
              : message
          )
        );
      }, 4000);
    } catch (error) {
      console.error("Feedback submission failed:", error.message);
    }
  };

  const handleFeedback = (messageId, feedback) => {
    sendFeedback(messageId, feedback);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (!typing) {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.sender === "mishraji" && msg.typing
            ? { ...msg, text: responseText, typing: false }
            : msg
        )
      );
    }
  }, [typing, responseText]);

  return (
    <div className="overflow-y-scroll h-[90vh]">
      {messages.length === 0 && (
        <IntroDashboard handleQuestionClick={handleQuestionClick} />
      )}

      <div className="w-[95%] mx-auto lg:w-[73%] sm:w-[90%] mt-[5vh]">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender} ${msg.direction}`}>
            {msg.sender === "mishraji" && (
              <div className="message-avatar-container">
                <img
                  src={mishrajiAvatar} // Image for Mishraji
                  alt="Mishraji"
                  className="w-[6vh] h-[6vh] shadow-md rounded-full"
                />
              </div>
            )}
            <div className={`message-content ${msg.sender}`}>
              {msg.sender === "user" && (
                <div className="message-avatar-container">
                  <img
                    src={userimg} // Image for User
                    alt="User"
                    className="w-[6vh] ml-[1vh] h-[6vh] shadow-md rounded-full"
                  />
                </div>
              )}
              <div className="message-text">
                {msg.sender === "mishraji" && msg.typing ? (
                  <div>{typedText}</div>
                ) : (
                  msg.text
                )}
                {msg.sender === "mishraji" && msg.showFeedback && (
                  <div
                    className="flex flex-row my-[1vh] justify-center shadow-md sm:gap-x-3 feedback rounded-xl"
                    style={{
                      backgroundColor: msg.feedback
                        ? msg.feedback === "like"
                          ? "#77dd77"
                          : "#FF6961"
                        : "#ffffff",
                    }}
                  >
                    {msg.showResponse && (
                      <p className="sm:text-[1.7vh] lg:text-[2.1vh] p-[1vh] text-white text-center">
                        {msg.feedback === "like"
                          ? "Thank you for your feedback!"
                          : "Sorry can you reframe your question again!"}
                      </p>
                    )}

                    {!msg.showResponse && !msg.feedback && (
                      <div className="flex flex-row px-3 py-2 gap-x-[5vh] items-center">
                        <p className="sm:text-[1.7vh] lg:text-[2.1vh] font-medium text-black">
                          Is it helpful?
                        </p>
                        <div className="flex flex-row gap-x-2">
                          <ThumbUpOffAltIcon
                            onClick={() => handleFeedback(msg.id, "like")}
                            className="cursor-pointer text-green-600"
                            style={{ fontSize: "3vh" }}
                          />
                          <ThumbDownOffAltIcon
                            onClick={() => handleFeedback(msg.id, "dislike")}
                            className="cursor-pointer text-red-500"
                            style={{ fontSize: "3vh" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        {loading && (
          <div className="flex justify-start items-center my-4 gap-x-1 relative">
            <img
              src={mishrajiAvatar}
              className="w-[6vh] h-[6vh] shadow-md rounded-full"
              alt="Mishraji Avatar"
            />
            <div className="loading">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} /> 
      </div>

      <div className="lg:w-[73%] w-[90%] bottom-[1.6vh] left-1/2 -translate-x-[50%] fixed">
        <MishrajiInput
          sendMessage={sendMessage}
          inputText={inputText}
          setInputText={setInputText}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default Chat;
