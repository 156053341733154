import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Toaster } from "react-hot-toast";
// import Navbar from "./components/Navbar";
import Login from "./routes/Login";
import Chat from "./routes/Chat";
import MainDashboard from "./routes/MainDashboard";
import NotFound from "./routes/NotFound";
import ProtectedRoute from "./context/ProtectedRoute";

function App() {
  return (
    <div className="bg-slate-50">
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<MainDashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/chat" element={<ProtectedRoute element={<Chat/>} />} />
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </Router>

      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
          style: {
            background: "#000",
            color: "#fff",
            borderRadius: "none",
            padding: "2vh",
            fontSize: "2.2vh",
          },
          success: {
            style: {
              background: "#000",
              color: "#fff",
              borderRadius: "none",
              padding: "2vh",
              fontSize: "2.2vh",
            },
          },
          error: {
            style: {
              background: "#000",
              color: "#fff",
              borderRadius: "none",
              padding: "2vh",
              fontSize: "2.2vh",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
