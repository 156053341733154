import React, { useState, useRef } from "react";
import MicIcon from "@mui/icons-material/Mic";
import micgif from "../assets/openmic.gif";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";


const MishrajiInput = ({ sendMessage, disabled }) => {
  const [micopen, setMicopen] = useState(false);
  const [message, setMessage] = useState("");
  // const [isInputFocused, setIsInputFocused] = useState(false);
  const timeoutRef = useRef(null);

  const togglemic = () => {
    if (!micopen) {
      setMicopen(true);
      timeoutRef.current = setTimeout(() => {
        setMicopen(false);
      }, 3000);
    } else {
      setMicopen(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  };

  const handleSend = () => {
    if (message.trim() !== "" && !disabled) {
      sendMessage(message);
      setMessage("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" ) {
      handleSend();
    }
  };

  return (
    <div className="border-1 bg-white border-[#bfbfbf] overflow-hidden shadow-2xl backdrop-blur-lg flex flex-row justify-between mx-auto px-[2vh] py-[1vh] rounded-lg">
      <input
        type="text"
        placeholder="Ask Question??"
        className="sm:text-[1.7vh] lg:text-[2.3vh] bg-transparent focus:outline-none w-[86%] text-[#3f1c0b] py-[0.5vh] px-[0.5vh]"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
      />

      <div className="flex flex-row items-center gap-x-[1.5vh] mr-[0.5vh]">
        {/* <div className="cursor-pointer">
          {micopen ? (
            <img
              src={micgif}
              className="w-[5vh]"
              alt="Microphone"
              onClick={togglemic}
            />
          ) : (
            <MicIcon
              style={{ fontSize: "4.5vh" }}
              className="text-[#9fa1a3]"
              onClick={togglemic}
            />
          )}
        </div> */}
        <div className="cursor-pointer" onClick={handleSend}>
          <ArrowUpwardIcon
            style={{ fontSize: "4vh" }}
            className={`p-[0.5vh] text-white rounded-md ${
              message.trim() && !disabled ? "bg-orange-500" : "bg-[#9fa1a3]"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default MishrajiInput;
