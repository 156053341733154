import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TypeAnimation } from "react-type-animation";

const store = [
  {
    question: "How do I make a new store layout?",
  },
  {
    question: "How do I renovate my store?",
  },
  {
    question: "How can I optimize shelves for better sales?",
  },
];
const product = [
  {
    question: "What are the different products to keep in my store?",
  },
  {
    question: "How can I increase product margins?",
  },
  {
    question: "What strategies can I use to manage slow-moving inventory?  ",
  },
];
const operation = [
  {
    question: "How do I keep offers in my store?",
  },
  {
    question: "How do I know if my store is profitable or not?",
  },
  {
    question: "How do I choose the right suppliers for my store?",
  },
];

const IntroDashboard = ({ handleQuestionClick }) => {
  const handleQuestionSelect = (question) => {
    handleQuestionClick(question);
  };

  return (
    <div className="lg:w-[73%] sm:w-[90%] w-[90%] mx-auto mt-[3vh] sm:mt-[4vh] lg:mt-[8vh]">
      <img
        src="/misrajiTab.png"
        className="w-[20vh] mx-auto my-[3vh]"
        alt="mishraji-logo"
      />

      <h1 className="lg:text-[3.3vh] sm:text-[2vh] text-[2.2vh] font-bold text-[#626263] text-center">
        Namaste 🙏🏻
      </h1>
      <div className="flex justify-center items-center">
        <TypeAnimation
          className="lg:text-[3vh] sm:text-[2vh] text-[2.2vh] text-[#626263] mt-3 font-inter text-center"
          sequence={[
            "You can ask your question in English!",
            1000,
            "आप अपने सवाल हिंदी में पूछ सकते हैं।",
            1000,
            "आपले प्रश्न मराठीत विचारू शकता।",
            1000,
            "Aap apne sawal Hinglish mein pooch sakte hain.",
            1000,
            "ನೀವು ನಿಮ್ಮ ಪ್ರಶ್ನೆಗಳನ್ನು ಕನ್ನಡದಲ್ಲಿ ಕೇಳಬಹುದು.",
            1000,
            "మీరు మీ ప్రశ్నలను తెలుగులో అడగవచ్చు.",
            1000,
            "நீங்கள் உங்கள் கேள்விகளை தமிழில் கேட்கலாம்.",
            1000,
            "നിങ്ങളുടെ ചോദ്യങ്ങൾ മലയാളത്തിൽ ചോദിക്കാം.",
            1000,
            "તમે ваши પ્રશ્નો ગુજરાતીમાં પુછવામાં શકે છે.",
            1000,
          ]}
          wrapper="span"
          speed={50}
          repeat={Infinity}
        />
      </div>

      <div className="my-[5vh] flex flex-col gap-y-[3vh] lg:flex-row  gap-x-5 bg-white py-[2.5vh] px-[1.5vh] lg:px-[2.3vh] rounded-2xl border-1 border-slate-100 shadow-slate-100 shadow-xl">

        <div className="flex flex-col lg:w-[33%]">
          
          <p className="lg:w-[90%] lg:text-[2.1vh] xl:text-[2.3vh] text-[2vh] mx-auto sm:text-[2vh] font-semibold mb-[2vh]">
            🏪 Store Related Questions
          </p>

          {store.map((item, index) => (
            <div
              key={index}
              className="select-none cursor-pointer mb-3 border-r-3 border-b-3 border-[#daeeff] shadow-sm lg:h-[12vh] active:bg-[#daeeff] p-[2vh] rounded-xl  flex flex-row items-center justify-between bg-[#f0f8ff]"
              onClick={() => handleQuestionSelect(item.question)}
            >
              <p className="sm:text-[1.5vh] lg:text-[2.1vh] w-[90%] text-left text-[#2a2a2a]">
                {item.question}
              </p>
              <ArrowForwardIosIcon className="text-[#656565] ml-2 " />
            </div>
          ))}
        </div>

        <div className="flex flex-col lg:w-[33%]">
          
          <p className="lg:w-[90%] lg:text-[2.1vh] xl:text-[2.3vh] text-[2vh] mx-auto sm:text-[2vh] font-semibold mb-[2vh]">
            📦 Product Related Questions
          </p>

          {product.map((item, index) => (
            <div
              key={index}
              className="select-none cursor-pointer mb-3 border-r-3 border-b-3 border-[#ffe0cf] shadow-sm lg:h-[12vh] active:bg-[#ffb584] p-[2vh] rounded-xl  flex flex-row items-center justify-between bg-[#ffefe6]"
              onClick={() => handleQuestionSelect(item.question)}
            >
              <p className="sm:text-[1.5vh] lg:text-[2.1vh] w-[90%] text-left text-[#2a2a2a]">
                {item.question}
              </p>
              <ArrowForwardIosIcon className="text-[#656565] ml-2 " />
            </div>
          ))}
        </div>

        <div className="flex flex-col justify-between lg:w-[33%]">
          
          <p className="lg:w-[90%] lg:text-[2.1vh] xl:text-[2.3vh] text-[2vh] mx-auto sm:text-[2vh] font-semibold mb-[2vh]">
            ⚙️ Operation Related Question
          </p>

          {operation.map((item, index) => (
            <div
              key={index}
              className="select-none cursor-pointer mb-3 border-r-3 border-b-3 border-[#daeeff] shadow-sm lg:h-[12vh] active:bg-[#daeeff] p-[2vh] rounded-xl  flex flex-row items-center justify-between bg-[#f0f8ff]"
              onClick={() => handleQuestionSelect(item.question)}
            >
              <p className="sm:text-[1.5vh] lg:text-[2.1vh] w-[90%] text-left text-[#2a2a2a]">
                {item.question}
              </p>
              <ArrowForwardIosIcon className="text-[#656565] ml-2 " />
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};

export default IntroDashboard;
