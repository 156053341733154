import React , {createContext, useContext, useState, useEffect} from 'react';
import cookie from "cookie";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider=({children})=>{
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [loading,setIsLoading] = useState(true);

    useEffect(()=>{
        const cookies = cookie.parse(document.cookie);
        const token = cookies.authToken; 
        const storedMobileNumber = cookies.mobileNumber || "";

        console.log("Token on load:", token);
        if(token){
            setIsAuthenticated(true);
            setMobileNumber(storedMobileNumber);
        }else{
            setIsAuthenticated(false)
        }

        setIsLoading(false);
    },[]);

    const login = (token, mobileNumber)=>{
        console.log("Logging in with token:", token)
        document.cookie = cookie.serialize("authToken", token, {
            maxAge: 60 * 60 * 24 * 7, // 7 days
            path: '/', // Path for the cookie
            secure:true,
            // httpOnly:true,
            // sameSite:"strict"
        });
        document.cookie = cookie.serialize("mobileNumber", mobileNumber, {
            maxAge: 60 * 60 * 24 * 7, // 7 days
            path: '/',
            secure: true,
        });
        setIsAuthenticated(true);
        setMobileNumber(mobileNumber);
    };

    const logout =()=>{
        document.cookie = cookie.serialize("authToken", "", {
            maxAge: -1, // Delete cookie
            path: '/',
            secure:true,
            // httpOnly:true,
            // sameSite:"none"
        });
        document.cookie = cookie.serialize("mobileNumber", "", {
            maxAge: -1, // Delete mobileNumber cookie
            path: '/',
            secure: true,
        });
        setIsAuthenticated(false);
    };

    if(loading){
        return <div>Loading...</div>
    }

    return(
        <AuthContext.Provider value={{isAuthenticated, login, logout, mobileNumber}}>
            {children}
        </AuthContext.Provider>
    )
}