import React, { useState } from "react";
import india from "../../assets/india.png";
import toast from "react-hot-toast";
import "../../App.css";
import { sendOTP } from "../../service/AuthService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ElevatorSharp } from "@mui/icons-material";
import { useAuth } from "../../context/AuthContext";

const EnterMobNumber = ({ onSubmit }) => {

  const [mobileNumber, setMobileNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");

  // const handleChange = (e) => {
  //   const input = e.target.value;
  //   const cleaned = input.replace(/\D/g, "");
  //   const trimmed = cleaned.slice(0, 10);
  //   setMobileNumber(trimmed);
  // };
  const handleChange = (value) => {
    setMobileNumber(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!mobileNumber || mobileNumber.length !== 11) {
    //   toast.error("Please enter a valid mobile number");
    //   return;
    // }

    if (!mobileNumber || mobileNumber.length < 10) {
      toast.error("Please enter a valid mobile number with at least 10 digits");
      return;
    }
    
    setIsLoading(true);

    try {
      const response = await sendOTP(mobileNumber);
      console.log(response);

      if(response.data){
        const verifyId = response.data.verify_id;
        setIsLoading(false);
        onSubmit(mobileNumber, verifyId);
        toast.success("OTP sent successfully!!");
      }else{
        setIsLoading(false);
        toast.error("Failed to send OTP");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setIsLoading(false);
      toast.error("Error sending OTP");
    }
  };

  const countryOptions = [
    { value: "+91", label: "India", countryCode: "IN" },
    { value: "+1", label: "United States", countryCode: "US" },
    // Add more countries as needed
  ];

  return (
    <div className="md:w-[70%]  mx-auto flex flex-col justify-center items-center">
      <form
        onSubmit={handleSubmit}
        className="w-[100%] my-[2vh] flex flex-col justify-center items-center"
      >
        {/* <img src={india} className="w-[3vh] h-[3vh]" alt="india" /> */}
        <PhoneInput
          international
          country={"in"}
          value={mobileNumber}
          onChange={handleChange}
          inputStyle={{ width: "100%", fontSize: "2.4vh", paddingLeft: "9vh", paddingTop: "3vh", paddingBottom: "3vh" }}
          buttonStyle={{ backgroundColor: "#fff", fontSize: "2.4vh", paddingLeft: "1vh", paddingRight: "1vh", paddingTop: "2vh", paddingBottom: "2vh" }}
          inputClass="font-medium focus:outline-none text-[1.8vh] md:text-[3vh]"
        />

        <button
          type="submit"
          className="text-[1.7vh] md:text-[2.5vh] my-[3vh] rounded-md font-bold bg-[#fd6500] active:bg-[#ee6001] hover:scale-105 transition-all text-white w-[100%] mx-auto py-[1vh] md:py-[1.3vh]"
          value="Submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex flex-row justify-center items-center">
              <div className="spinner"></div>
            </div>
          ) : (
            <>Submit</>
          )}
        </button>
      </form>
    </div>
  );
};

export default EnterMobNumber;
